import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { MEMBERS_AREA } from '@wix/app-definition-ids';
import { MembersAreaAppId } from '@wix/members-area-app-definitions';

export enum MembersAreaContext {
  V1 = 'v1',
  V2 = 'v2',
  V3 = 'v3',
}

interface MembersAreaPublicApi {
  getIsMembersAreaV2Context: () => Promise<boolean>;
  getMembersAreaContext?: () => Promise<MembersAreaContext>;
  addApplications: (
    applications: MembersAreaAppId[],
    shouldNavigate?: boolean,
  ) => Promise<void>;
}

const getMembersAreaApi = (editorSDK: FlowEditorSDK) => {
  return editorSDK.document.application.getPublicAPI('', {
    appDefinitionId: MEMBERS_AREA,
  }) as Promise<MembersAreaPublicApi | undefined>;
};

export const getMembersAreaContext = async (editorSDK: FlowEditorSDK) => {
  try {
    const membersAreaApi = await getMembersAreaApi(editorSDK);

    if (membersAreaApi?.getMembersAreaContext) {
      return membersAreaApi.getMembersAreaContext();
    }

    const isMembersAreaV2 = await membersAreaApi?.getIsMembersAreaV2Context();

    return isMembersAreaV2 ? MembersAreaContext.V2 : MembersAreaContext.V1;
  } catch (e) {
    return MembersAreaContext.V1;
  }
};

export const isMembersAreaV1Context = async (editorSDK: FlowEditorSDK) => {
  const membersAreaContext = await getMembersAreaContext(editorSDK);

  return membersAreaContext === MembersAreaContext.V1;
};

export const addApplications = async (
  editorSDK: FlowEditorSDK,
  applications: MembersAreaAppId[],
) => {
  const membersAreaApi = await getMembersAreaApi(editorSDK);
  return membersAreaApi?.addApplications(applications);
};
