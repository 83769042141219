import type { ExportsFn } from '@wix/platform-editor-sdk';
import type {
  ComponentRef,
  EditorReadyFn,
  GetAppManifestFn,
} from '@wix/yoshi-flow-editor';

import profileCardConfig from './components/ProfileCard/.component.json';
import { createAppAPI } from './editor/services/app-api';
import * as publicApi from './editor/services/public-api';
import { maybeSetHorizontalLayout } from './editor/services/styles';
import { initMonitoring, toMonitored } from './editor/services/monitoring';
import { isMembersAreaV1Context } from './editor/services/members-area-api';
import { getEditorAPI } from './editor/services/editor-api';
import { globalAppState } from './editor/services/global-app-state';
import { Experiment } from './constants/experiments';

let appDefId: string;

const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  options,
  flowAPI,
) => {
  appDefId = appDefinitionId;
  globalAppState.setFlowAPI(flowAPI);
  await initMonitoring(editorSDK, options, flowAPI);

  if (!options.firstInstall) {
    return;
  }

  const isADI = options.origin.type.includes('ADI');
  const isHorizontalLayout = !isADI;

  if (isHorizontalLayout) {
    await toMonitored('setHorizontalLayout', () =>
      maybeSetHorizontalLayout(editorSDK, appDefId),
    );
  }
};

const _exports: ExportsFn = (editorSDK) => ({
  public: {
    isHorizontalLayout: () => publicApi.isHorizontalLayout(editorSDK, appDefId),
    refreshApp: () => {
      const flowAPI = globalAppState.getFlowAPI();

      if (!flowAPI) {
        throw new Error(
          '[Profile Card - refreshApp ] Flow API is not available',
        );
      }

      return publicApi.refreshApp(
        editorSDK,
        flowAPI.experiments,
        'PUBLIC_API_REFRESH',
      );
    },
    setBooleanStyleParamForWidgets: (key: string, value: boolean) =>
      publicApi.setBooleanStyleParamForWidgets(editorSDK, appDefId, key, value),
    setHorizontalLayout: (widgetRef?: ComponentRef) =>
      publicApi.setHorizontalLayout(editorSDK, appDefId, widgetRef),
    setSidebarLayout: () => publicApi.setSidebarLayout(editorSDK, appDefId),
  },
  private: createAppAPI(editorSDK),
  editor: getEditorAPI(editorSDK),
});

export const getAppManifest: GetAppManifestFn = async (
  { appManifestBuilder },
  editorSDK,
  _contextParams,
  flowAPI,
) => {
  const isMembersAreaV1 = await isMembersAreaV1Context(editorSDK);
  const removable =
    isMembersAreaV1 ||
    flowAPI.experiments.enabled(Experiment.MakeProfileCardRemovableInNewMA);

  return appManifestBuilder
    .configureWidget(profileCardConfig.id, (builder) => {
      builder.behavior().set({ removable });
      builder.gfpp().set('widgetPlugins', { behavior: 'HIDE' });
    })
    .build();
};

export { editorReady, _exports as exports };
